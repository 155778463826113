<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-4">
        <map-component
          mode="mapping"
        />
      </v-col>
      <v-col class="right-area ma-4">
        <v-row
          class="header"
          justify="space-around"
        >
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="cancelMapping"
          >
          <span
            class="btn-cancel"
            type="button"
            @click="cancelMapping"
          >&nbsp;&nbsp;취소</span>
          <v-spacer />
          <span
            class="btn-save"
            type="button"
            @click="saveMapping"
          >저장&nbsp;</span>
        </v-row>
        <v-row>
          <v-col>
            <input
              v-model="name"
              type="text"
              class="input-map-name"
              placeholder="지도명 입력"
            >
          </v-col>
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import MapComponent from '@/components/map/map.vue';
import DefaultHeader from '@/components/header/defaultHeader.vue';
import mapping from '../assets/mapping.mpeg';
import nav from '../assets/nav.mpeg';

export default {
  name: 'Mapping',
  components: {
    DefaultModal,
    MapComponent,
    DefaultHeader,
  },
  props: {},
  data () {
    return {
      showModal: false,
      modalState: {
        message: null,
        action: null
      },
      name: '',
      map_id: null,
      interval: () => {},
    };
  },
  created () {
    this.initialize();
  },
  beforeDestroy(){
    clearInterval(this.interval);
  },
  methods: {
    initialize() {
      this.modalState.message= '맵핑모드로 변경중입니다. 잠시만 기다려주세요.';
      this.modalState.action= 'no-action';
      this.showModal= true;
      setTimeout(()=> {
        this.showModal= false;
      }, 5000);
      return this.cancelMoving()
        .then(this.fetchData)
        .then(this.getMode)
        .then(res => {
          if (res.data.mode === 'NAV') {
            let mappingAudio = new Audio(mapping);
            mappingAudio.play();
            return this.changeToMapping();
          }
        })
        .catch(err => { throw Error(err); });
    },
    cancelMoving() {
      return this.$axios.put('/nav/cancel');
    },
    fetchData() {
      return this.$axios.get('/info')
        .then(res => {
          if (res.status === 200) {
            this.map_id = res.data.map_id;
          }
        });
    },
    getMode() {
      return this.$axios.get('/mode');
    },
    changeToMapping() {
      return this.$axios.put('/mode/change/mapping');
    },
    cancelMapping() {
      this.modalState.message = '매핑을 중단할까요?';
      this.modalState.action = '매핑 중단';
      this.showModal = true;
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    },
    saveMapping() {
      let isNew = true;
      if (this.name.length) {
        return this.$axios.get('/map/list')
          .then(res => {
            if (res.status === 200) {
              res.data.forEach(el => {
                if (el.map_name === this.name) {
                  isNew = false;
                }
              });
              if (isNew) {
                this.modalState.message = '매핑완료 및 지도저장을 진행할까요?';
                this.modalState.action = '지도 저장';
                this.showModal = true;
              } else {
                this.$toast('중복된 지도명입니다');
              }
            }
          })
          .catch(err => { throw Error(err); });
      } else {
        this.$toast('지도명을 입력해 주세요');
      }
    },
    doAction() {
      if (this.modalState.action === '지도 저장') {
        this.modalState.message = '지도를 저장 중입니다';
        this.modalState.action = 'no-action';
        this.saveMap();
      } else {
        this.loadingModal();
        this.getMode()
          .then(res => {
            if (res.data.mode !== 'NAV') {
              return this.changeToNav();
            } else {
              this.$router.replace('/config');
            }
          })
          .catch(err => { throw Error(err); });
      }
    },
    loadingModal() {
      this.modalState.message = '매핑 모드를 종료 중입니다';
      this.modalState.action = 'no-action';
    },
    saveMap() {
      let navAudio = new Audio(nav);
      navAudio.play();
      return this.$axios.post('/map', {
          mapName: this.name
      })
        .then(res => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$router.replace('/mapinfo');
            }, 5000);
          }
        })
        .catch(err => {
          this.closeModal();
          throw Error(err);
        });
    },
    changeToNav() {
      let navAudio = new Audio(nav);
      navAudio.play();
      return this.$axios.put(`/mode/change/nav/${this.map_id}`,{
          mapid: this.map_id
      })
        .then(res => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$router.replace('/config');
            }, 5000);
          }
        })
        .catch(err => {
          this.closeModal();
          throw Error(err);
        });
    },
  }
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 100vh;
}

.btn-cancel {
  color: #ffffff;
  font-size: 80px !important;
}

.btn-save  {
  justify-self: right;
  color: #ffffff;
  font-size: 80px !important;
}

.input-map-name {
  width: 100%;
  height: 100px;
  margin: 4rem 0 0;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-map-name:focus {
  outline: #9aa0ae solid;
}

@media (max-width: 1040px) {
  .btn-cancel {
    font-size: 40px !important;
  }

  .btn-save {
    font-size: 40px !important;
  }

  .header img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1024px) {
  .btn-cancel {
    font-size: 20px !important;
  }

  .btn-save {
    font-size: 20px !important;
  }

  .header img {
    width: 30px;
    height: 30px
  }

  .input-map-name {
    height: 60px;
    margin: 50px 0 0!important;
    font-size: 1rem;
  }

}
</style>
